
import { Vue, Component, Watch } from 'vue-property-decorator'
@Component
export default class Tree extends Vue {
  private src = ''
  isMain = this.$route.path.indexOf('homePage') !== -1
  scale = window.devicePixelRatio

  @Watch('$route')
  routeChange () {
    this.init()
  }

  created () {
    this.init()
    window.onresize = () => {
      this.scale = window.devicePixelRatio
    }
  }

  init () {
    const url = window.location.href.split('#/')[0] + 'lowsaas/saas/'
    this.src = url + this.$route.query.param
  }
}
